import axios from "axios";
const baseUrl = `https://apiv3.apifootball.com/?action=`;
const baseUrlFutzone = `https://sws-news.uz/api/v1`;

const apiKey =
  "e9fae4a52ae9d09b88762108b140bfc2af68dc2b61022fb200fe3e7521313354";

  const token = localStorage.getItem("token");


export const ApiServer = {
  async getEventsData(from, to, liga_id) {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}get_events&from=${from}&to=${to}&league_id=${liga_id}&APIkey=${apiKey}`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  },
  async getMatchData(match_id) {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}get_events&match_id=${match_id}&APIkey=${apiKey}`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  },
  async getStandingData(liga_id) {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}get_standings&league_id=${liga_id}&APIkey=${apiKey}`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  },
  async getStatisticData(match_id) {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}get_statistics&match_id=${match_id}&APIkey=${apiKey}`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  },
  async getPredictionsData(from, to) {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}get_predictions&from=${from}&to=${to}&APIkey=${apiKey}`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  },
  async getTopScoreData(league_id) {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}get_topscorers&league_id=${league_id}&APIkey=${apiKey}`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  },
  async getFutzoneData(url) {
    const response = await axios({
      method: "GET",
      url: `${baseUrlFutzone}${url}`,
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
        Secret:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3Mjg1OTM2ODUsIm1lc3NhZ2UiOiJGdXR6b25lIHRva2VuIn0.gMPulF77Q5-3bgzUELF-rNGqWds0F4n_c9SZ3CGCqns",
      },
    });
    return response;
  },
  async postFutzoneData(url, body) {
    const response = await axios({
      method: "POST",
      url: `${baseUrlFutzone}${url}`,
      headers: {
        "Content-Type": "application/json",
        Secret:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3Mjg1OTM2ODUsIm1lc3NhZ2UiOiJGdXR6b25lIHRva2VuIn0.gMPulF77Q5-3bgzUELF-rNGqWds0F4n_c9SZ3CGCqns",
      },
      data: body,
    });
    return response;
  },
  async putFutzoneData(url, body) {
    const response = await axios({
      method: "PUT",
      url: `${baseUrlFutzone}${url}`,
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${token}`,
        Secret:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3Mjg1OTM2ODUsIm1lc3NhZ2UiOiJGdXR6b25lIHRva2VuIn0.gMPulF77Q5-3bgzUELF-rNGqWds0F4n_c9SZ3CGCqns",
      },
      data: JSON.stringify(body),
    });
    return response;
  },
  async postFutzoneFileData(url, body) {
    const response = await axios({
      method: "POST",
      url: "https://sws-news.uz/api/v1/files/upload",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${token}`,
        Secret:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3Mjg1OTM2ODUsIm1lc3NhZ2UiOiJGdXR6b25lIHRva2VuIn0.gMPulF77Q5-3bgzUELF-rNGqWds0F4n_c9SZ3CGCqns",
      },
      data: body,
    });
    return response;
  },
};
